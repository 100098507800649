/**
 * Created by taylor.houston on 11/1/2018.
 */
import { FACET_GROUP_TOGGLE, FACET_GROUP_EXPAND, FACET_GROUP_BUILD, FACET_GROUP_ADD } from '../constants/facetGroupConstants'

const initialState = {
  Category: { open: true, expanded: false },
  Specialties: { open: false, expanded: false },
  Specialty: { open: false, expanded: false },
  Brands: { open: true, expanded: false },
  'Review Rating': { open: false, expanded: false },
  'Swanson Brands': { open: false, expanded: false },
  Potency: { open: false, expanded: false },
  Form: { open: false, expanded: false },
  'Pill Size': { open: false, expanded: false },
  'More Ways to Browse': { open: true, expanded: false },
  'Special Offers': { open: true, expanded: false },
  'Key Ingredient': { open: false, expanded: false },
  'Features': { open: false, expanded: false },
  Concern: { open: true, expanded: false },
  Lifestage: { open: false, expanded: false },
  Quantity: { open: false, expanded: false },
  Homeopathic: { open: false, expanded: false },
  Flavor: { open: false, expanded: false },
  'Protein Type': { open: false, expanded: false },
  Scent: { open: false, expanded: false },
  '# of Reviews': { open: false, expanded: false },
  Price: { open: false, expanded: false },
  facets: {
    facetKeys: window?.initialState?.searchResultsDTO?.facetKeys,
    facetsMap: window?.initialState?.searchResultsDTO?.facetsMap,
    moreFacetMap: window?.initialState?.searchResultsDTO?.moreFacetsMap
  }
}

const facetGroupReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case FACET_GROUP_TOGGLE:
      return { ...reduxState,
        [action.name]: {
          open: !reduxState[action.name].open,
          expanded: false
        }
      }

    case FACET_GROUP_EXPAND:
      return {
        ...reduxState,
        [action.name]: {
          open: true,
          expanded: !reduxState[action.name].expanded
        }
      }

    case FACET_GROUP_BUILD:
      return { ...reduxState, facets: action.payload }

    case FACET_GROUP_ADD:
      return {
        ...reduxState,
        facets: {
          ...reduxState.facets,
          facetsMap: {
            ...reduxState.facets.facetsMap,
            [action.payload.facetGroupName]:
                  [...reduxState.facets.facetsMap[action.payload.facetGroupName], ...action.payload.data]
          }
        }
      }

    default:
      return reduxState
  }
}

export default facetGroupReducer
