import { PAGE_PULL, PAGE_SORT_PULL } from '../constants/pageConstants'

const convertToSelectBoxData = (payload) => {
  let payloadInstance = payload
  payloadInstance.forEach(sortOption => {
    delete Object.assign(sortOption, { text: sortOption['label'] })['label']
  })
  return payloadInstance
}
const initialState = { page: window?.initialState?.searchResultsDTO?.pagesDTO, sorts: convertToSelectBoxData(window?.initialState?.searchResultsDTO?.sortDTOList) }

const pageReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case PAGE_PULL:
      return { ...reduxState, page: action.payload }
    case PAGE_SORT_PULL:
      const convertedPayload = convertToSelectBoxData(action.payload)
      return { ...reduxState, sorts: convertedPayload }

    default:
      return reduxState
  }
}

export default pageReducer
