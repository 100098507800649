import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import searchReducer from '../reducers/searchReducer'
import appReducer from '../reducers/appReducer'
import facetGroupReducer from '../reducers/facetGroupReducer'
import cartReducer from '../reducers/cartReducer'
import pageReducer from '../reducers/pageReducer'
import breadcrumbReducer from '../reducers/breadcrumbReducer'
import routerReducer from '../reducers/routerReducer'
import compareReducer from '../reducers/compareReducer'
import analyticMiddleware from '../middleware/analyticMiddleware'
import notificationReducer from '../reducers/notificationReducer'
import sharedReducer from '../reducers/sharedReducer'
import formReducer from '../reducers/formReducer'
import headerReducer from '../reducers/headerReducer'
import { errorReducer } from '../reducers/errorReducer'
import routerMiddleware from '../middleware/routerMiddleware'
import locationListener from '../listeners/locationListener'
import loginReducer from '../reducers/loginReducer'
import { createBrowserHistory } from 'history'
let history = null
const middlewares = [
  thunk,
  analyticMiddleware
]
if(typeof window.location !== 'undefined') {
  history = createBrowserHistory()
  middlewares.push(routerMiddleware(history))
}

const store = createStore(combineReducers({
  productData: searchReducer,
  app: appReducer,
  forms: formReducer,
  breadcrumb: breadcrumbReducer,
  facetGroup: facetGroupReducer,
  cart: cartReducer,
  page: pageReducer,
  router: routerReducer,
  compare: compareReducer,
  notification: notificationReducer,
  shared: sharedReducer,
  header: headerReducer,
  errors: errorReducer,
  login: loginReducer
}),
compose(
  applyMiddleware(...middlewares),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
)
if (typeof window.location !== 'undefined') {
  locationListener(history, store)
}

export default store
