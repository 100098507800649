import { BREADCRUMB_UPDATE, BREADCRUMB_REMOVE } from '../constants/breadcrumbConstants'

const initialState = { breadcrumb: window?.initialState?.searchResultsDTO?.breadCrumbsDTO?.breadCrumbsList }

const breadcrumbReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case BREADCRUMB_UPDATE: {
      return { ...reduxState, breadcrumb: action.payload }
    }

    case BREADCRUMB_REMOVE: {
      const index = parseInt(action.payload)
      return { ...reduxState,
        breadcrumb: [...reduxState.breadcrumb.slice(0, index), ...reduxState.breadcrumb.slice(index + 1)]
      }
    }

    default:
      return reduxState
  }
}

export default breadcrumbReducer
