/**
 * Created by taylor.houston on 9/24/2018.
 */
import { PRODUCT_DATA_GET, PROMOTION_DATA_GET, RECOMMENDATION_DATA_GET } from '../constants/searchConstants'

const initialState = {
  productData: window?.initialState?.searchResultsDTO?.adobeRecords,
  promotionData: window?.initialState?.searchResultsDTO?.promotionsDTO,
  recommendationData: window?.initialState?.recommendationResultList
}

const searchReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DATA_GET:
      return { ...reduxState, productData: action.payload }

    case PROMOTION_DATA_GET:
      return { ...reduxState, promotionData: action.payload }

    case RECOMMENDATION_DATA_GET:
      return { ...reduxState, recommendationData: action.payload}

    default:
      return reduxState
  }
}

export default searchReducer
