import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from './components/AppContainer/AppContainer'
import { SearchApp } from './components/_search/SearchApp/SearchApp'
import store from './store/store'
import { Header } from './components/_header/Header/Header'
import { Footer } from './components/Footer/Footer'
import { Instances } from './components/Instances/Instances'
import { SSRContainer } from './components/_ssr/SSRContainer/SSRContainer'
import { ServerStyleSheet } from 'styled-components'
import { renderToString } from 'react-dom/server'

const AppJSX = () => <AppContainer
    store={store}
  >
    <Header />
    <SearchApp />
    <Footer />
    <Instances />
  </AppContainer>

if(typeof window.location !== 'undefined') {
  ReactDOM.hydrate(
    <AppJSX />
    , document.getElementById('root')
  )
} else {
  window.render = function render() {
    const sheet = new ServerStyleSheet()
    let styleTags, html

      html = renderToString(
        <SSRContainer sheet={sheet}>
          <AppJSX />
        </SSRContainer>
      )
      styleTags = sheet.getStyleTags()

    return {
      html,
      styleTags
    }
  }
}