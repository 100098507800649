/**
 * Created by taylor.houston on 10/17/2018.
 */
import { LOADING, LOADING_COMPLETE, FILTER_MOBILE_MENU_TOGGLE, FILTER_MOBILE_MENU_CLOSE, APP_SORT, APP_SET_DATA, APP_ERROR } from '../constants/appConstants'

const initialState = {
  loading: false,
  filterMenuOpen: false,
  sort: 'relevance',
  title: window?.initialState?.searchResultsDTO?.title,
  keyword: window?.initialState?.searchResultsDTO?.kw,
  searchId: window?.initialState?.searchResultsDTO?.searchId,
  groupId: window?.initialState?.searchResultsDTO?.groupId,
  collectionId: window?.initialState?.searchResultsDTO?.collectionId,
  recordsFrom: window?.initialState?.searchResultsDTO?.numRecordsFrom,
  recordsTo: window?.initialState?.searchResultsDTO?.numRecordsTo,
  totalPageResults: window?.initialState?.searchResultsDTO?.numRecordsTotal,
  didYouMean: (window?.initialState?.searchResultsDTO?.didYouMean?.length > 0 && window?.initialState?.searchResultsDTO?.didYouMean[0]) || '',
  topBanner: window?.initialState?.searchResultsDTO?.promotionalContent || '',
  bottomBanner: window?.initialState?.searchResultsDTO?.bottomContent || '',
  topMobileBanner: window?.initialState?.searchResultsDTO?.mobilePromotionalContent || '',
  bottomMobileBanner: window?.initialState?.searchResultsDTO?.mobileBottomContent || '',
  footerInfo: window?.initialState?.searchResultsDTO?.seoFooter || '',
  productCardZone: window?.initialState?.searchResultsDTO?.productCardZone || '',
  cardOrderNumber: window?.initialState?.searchResultsDTO?.cardOrderNumber || '',
  zoneMap: window?.initialState?.searchResultsDTO?.zoneMap || '',
  h1: window?.initialState?.searchResultsDTO?.h1 || '',
  brandPage: window?.initialState?.searchResultsDTO?.brandPage || '',
  filterData: window?.initialState?.searchResultsDTO?.filterData || {},
  index: window?.initialState?.searchResultsDTO?.noIndex || false
}

const appReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return { ...reduxState, loading: true }
    }

    case LOADING_COMPLETE: {
      return { ...reduxState, loading: false }
    }

    case FILTER_MOBILE_MENU_TOGGLE: {
      return { ...reduxState,
        filterMenuOpen: !reduxState.filterMenuOpen
      }
    }

    case FILTER_MOBILE_MENU_CLOSE: {
      return { ...reduxState,
        filterMenuOpen: false
      }
    }

    case APP_SORT: {
      return { ...reduxState, sort: action.payload }
    }

    case APP_SET_DATA: {
      return { ...reduxState, ...action.payload }
    }

    case APP_ERROR: {
      return { ...reduxState, error: 'connection' }
    }

    default:
      return reduxState
  }
}

export default appReducer
